import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerChild} />
      <div className={styles.corpOlympicsCopyright}>
        <div className={styles.copyright2024}>
          Copyright © 2024 CorpOlympics
        </div>
        <div className={styles.lyonFrance}>69000 - Lyon, France</div>
      </div>
      <div className={styles.socialFrames}>
        <div className={styles.socials}>
          <img
            className={styles.twitterPhotoroom1Icon}
            loading="eager"
            alt=""
            src="/twitterphotoroom-1@2x.png"
          />
          <img
            className={styles.facebookIcon}
            loading="eager"
            alt=""
            src="/facebook@2x.png"
          />
          <img
            className={styles.linkedinIcon}
            loading="eager"
            alt=""
            src="/linkedin@2x.png"
          />
          <img
            className={styles.linkedinIcon1}
            alt=""
            src="/linkedin-1@2x.png"
          />
        </div>
        <div className={styles.contactcorpolympicscom}>
          contact@corpolympics.com
        </div>
      </div>
    </footer>
  );
};

export default Footer;
