import MainText from "../components/MainText";
import Cards from "../components/Cards";
import Footer from "../components/Footer";
import styles from "./Main.module.css";

const Main = () => {
  return (
    <div className={styles.main}>
      <section className={styles.rectangleParent}>
        <div className={styles.frameChild} />
        <img
          className={styles.pexelsPhotoByYogendraSing}
          alt=""
          src="/pexels-photo-by-yogendra--singh@2x.png"
        />
        <div className={styles.corpolympicsWrapper}>
          <h2 className={styles.corpolympics}>CorpOlympics</h2>
        </div>
        <MainText />
      </section>
      <Cards />
      <Footer />
    </div>
  );
};

export default Main;
