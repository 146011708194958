import styles from "./MainText.module.css";

const MainText = () => {
  return (
    <div className={styles.frameWrapper}>
      <div className={styles.rvolutionnerLeSportEnEntrParent}>
        <h1 className={styles.rvolutionnerLeSportContainer}>
          <p className={styles.rvolutionner}>{`Révolutionner `}</p>
          <p className={styles.leSportEn}>le sport en entreprise</p>
        </h1>
        <div className={styles.unSolutionSimpleContainer}>
          <p className={styles.unSolutionSimple}>
            Un solution simple, innovante et fiable pour améliorer la qualité de
            vie au travail.
          </p>
          <p
            className={styles.contribuerAuBien}
          >{`Contribuer au bien être de ses employés n’a jamais été aussi facile.  `}</p>
        </div>
          <a className={styles.cta} href="https://forms.gle/JsijZzFHNC1dAB477" target="_blank">
            <b className={styles.aidezNousDansNotre}>
              Aidez-nous dans notre démarche!
            </b>
            </a> 
      </div>
    </div>
  );
};

export default MainText;
