import styles from "./Cards.module.css";

const Cards = () => {
  return (
    <section className={styles.plateFrameAWrapper}>
      <div className={styles.plateFrameA}>
        <h1 className={styles.enQuelquesMots}>En quelques mots:</h1>
        <div className={styles.plateFrameAInner}>
          <div className={styles.plate1Parent}>
            <div className={styles.plate1}>
              <div className={styles.cestQuoiWrapper}>
                <h1 className={styles.cestQuoi}>C’est quoi ?</h1>
              </div>
              <div className={styles.avatarFrame}>
                <img
                  className={styles.avatarTennisPlayer1}
                  loading="eager"
                  alt=""
                  src="/avatar-tennis-player-1@2x.png"
                />
              </div>
              <div className={styles.footballPlayerAvatar} />
              <div className={styles.challengeFrame} />
              <div className={styles.uneApplicationPermettant}>
                Une application permettant aux employés de réserver facilement
                des séances de sport entre collègues en partenariat avec les
                établissements sportifs !
              </div>
            </div>
            <div className={styles.plate3}>
              <div className={styles.advantageRectangleParent}>
                <div className={styles.advantageRectangle} />
                <div className={styles.advantageRectangle1} />
              </div>
              <div className={styles.plate3Child} />
              <div className={styles.principleFrameWrapper}>
                <div className={styles.principleFrame}>
                  <h1 className={styles.principe}>Principe ?</h1>
                  <img
                    className={styles.avatarWomanRunning1}
                    loading="eager"
                    alt=""
                    src="/avatar-woman-running-1@2x.png"
                  />
                </div>
              </div>
              <div className={styles.dfiezVosCollgues}>
                Défiez vos collègues pour grimper dans le classement 
                ou participez à des tournois inter-entreprise pour
                réprésenter vos couleurs !
              </div>
            </div>
            <div className={styles.plate2}>
              <h1 className={styles.avantages}>Avantages ?</h1>
              <div className={styles.plate2Child} />
              <img
                className={styles.avatarFootballPlayer1}
                loading="eager"
                alt=""
                src="/avatar-football-player-1@2x.png"
              />
              <div className={styles.amliorerLaQualit}>
                Améliorer la qualité de vie des employés pour assurer la
                cohésion, la bonne ambiance et la productivité au travail !
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cards
;
